<template>
  <v-container>
    <BaseScreenHeader
      title="Top Pounders"
      width="90%"
      screenInfo="LDR-000"
      :showBack="true"
    />

    <v-sheet rounded class="px-2" color="charcoal" :style="charcoalTileMenu">
      <v-card-actions class="py-0">
        <v-spacer />
        <v-switch
          color="progressActive "
          class="mb-n2 "
          dark
          label="Relative to Body Weight"
          v-model="currentParams.normalized"
          @change="updateParams({ normalized: $event })"
        >
          <template v-slot:label>
            <span class="silver--text">Relative to Body Weight</span>
          </template>
        </v-switch>
      </v-card-actions>

      <v-row dense>
        <v-col cols="5">
          <v-select
            dark
            filled
            v-model="currentParams.age"
            :items="ageGroups"
            item-text="text"
            item-value="value"
            label="Age Group"
            color="success"
            @change="updateParams({ age: $event })"
          />
        </v-col>
        <v-spacer />
        <v-col cols="5">
          <v-select
            dark
            filled
            v-model="currentParams.gender"
            :items="genders"
            item-text="text"
            item-value="value"
            label="Sex"
            color="success"
            @change="updateParams({ gender: $event })"
          />
        </v-col>
      </v-row>
    </v-sheet>
    <v-sheet class="charcoal pt-2">
      <v-tabs
        active-class="progressActive charcoal--text"
        class="paper--text"
        v-model="tab"
        center-active
        centered
        grow
        dark
        icons-and-text
        show-arrows
        background-color="charcoal"
        @change="updateParams({ interval: $event })"
        v-touch:swipe.left="tabLeft"
        v-touch:swipe.right="tabRight"
      >
        <v-tabs-slider color="progressActive" />
        <v-tab href="#rolling_30" class="silverLink">
          ROLLING 30
          <v-icon>mdi-rewind-30</v-icon>
        </v-tab>
        <v-tab href="#monthly" class="silverLink">
          THIS MONTH
          <v-icon>mdi-calendar-month-outline</v-icon>
        </v-tab>
        <v-tab href="#weekly" class="silverLink">
          THIS WEEK
          <v-icon>mdi-numeric-7-box-multiple-outline</v-icon>
        </v-tab>
        <v-tab href="#daily" class="silverLink">
          LAST 24h
          <v-icon>mdi-store-24-hour</v-icon>
        </v-tab>
        <v-tab href="#yearly" class="silverLink">
          THIS YEAR
          <v-icon>mdi-calendar-clock</v-icon>
        </v-tab>
        <v-tab href="#all_time" class="silverLink">
          ALL TIME
          <v-icon>mdi-earth</v-icon>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-if="loaded" v-model="tab">
        <v-sheet class="charcoal">
          <v-tab-item key="all_time" value="all_time">
            <TabDetail
              :topPounders="topPounders"
              :normalized="currentParams.normalized"
              :gender="currentParams.gender"
              key="all_time"
            />
          </v-tab-item>
          <v-tab-item key="daily" value="daily">
            <TabDetail
              :topPounders="topPounders"
              :normalized="currentParams.normalized"
              :gender="currentParams.gender"
              key="daily"
            />
          </v-tab-item>
          <v-tab-item key="weekly" value="weekly">
            <TabDetail
              :topPounders="topPounders"
              :normalized="currentParams.normalized"
              :gender="currentParams.gender"
              key="weekly"
            />
          </v-tab-item>
          <v-tab-item key="montly" value="monthly">
            <TabDetail
              :topPounders="topPounders"
              :normalized="currentParams.normalized"
              :gender="currentParams.gender"
              key="montly"
            />
          </v-tab-item>
          <v-tab-item key="yearly" value="yearly">
            <TabDetail
              :topPounders="topPounders"
              :normalized="currentParams.normalized"
              :gender="currentParams.gender"
              key="yearly"
            />
          </v-tab-item>

          <v-tab-item key="rolling_30" value="rolling_30">
            <TabDetail
              :topPounders="topPounders"
              :normalized="currentParams.normalized"
              :sex="currentParams.gender"
            />
          </v-tab-item>
        </v-sheet>
      </v-tabs-items>
    </v-sheet>
  </v-container>
</template>

<script>
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'

const TabDetail = () =>
  import(/* webpackPrefetch: true */ '@/views/leaderboard/TabDetail.vue')

export default {
  mixins: [util],
  components: {
    TabDetail
  },
  data() {
    return {
      tab: null,
      loaded: false,
      response: {},
      tabs: [
        { index: '0', name: 'rolling_30' },
        { index: '1', name: 'monthly' },
        { index: '2', name: 'weekly' },
        { index: '3', name: 'daily' },
        { index: '4', name: 'yearly' },
        { index: '5', name: 'all_time' }
      ],
      ageGroups: [
        { text: 'All', value: 'all' },
        { text: 'Under 15', value: '00_to_14' },
        { text: '15-20', value: '15_to_20' },
        { text: '21-30', value: '21_to_30' },
        { text: '31-40', value: '31_to_40' },
        { text: '41-50', value: '41_to_50' },
        { text: '51-60', value: '51_to_60' },
        { text: '61-70', value: '61_to_70' },
        { text: 'Over 70', value: '71_to_99' }
      ],
      currentParams: {
        age: 'all',
        interval: 'rolling_30',
        gender: '%',
        page: 1,
        limit: 100,
        normalized: false
      },
      genders: [
        { text: 'All', value: '%' },
        { text: 'Female', value: 'female' },
        { text: 'Male', value: 'male' }
      ],
      topPounders: []
    }
  },
  computed: {
    ...appConfig
  },
  beforeMount() {
    this.loadLeaderboard()
  },
  methods: {
    tabLeft() {
      let currentTab = this.tabs.find(el => el.name == this.tab)
      let currentIndex = parseInt(currentTab.index)
      if (currentIndex < this.tabs.length - 1) {
        let nextTab = this.tabs.find(el => el.index == currentIndex + 1)
        this.tab = nextTab.name
      }
    },
    tabRight() {
      let currentTab = this.tabs.find(el => el.name == this.tab)
      let currentIndex = parseInt(currentTab.index)
      if (currentIndex > 0) {
        let nextTab = this.tabs.find(el => el.index == currentIndex - 1)
        this.tab = nextTab.name
      }
    },
    updateParams(params) {
      this.currentParams = { ...this.currentParams, ...params }
      this.loadLeaderboard()
    },
    loadLeaderboard() {
      axios
        .get(this.baseURL + '/leaderboard', {
          params: this.currentParams
        })
        .then(response => {
          if (response.status === 200) {
            let data = response.data.data
            this.topPounders = []
            if (data) {
              this.topPounders = data
            }
            this.loaded = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>
