<template>
  <v-container>
    <BaseScreenHeader
      title="Musclepedia"
      screenInfo="MSP-001"
      :showBack="true"
    />

    <v-row justify="center">
      <v-img
        class="paper rounded"
        :src="cdn('images', 'all-body-muscles-medium.png')"
        :srcset="
          cdn('images', 'all-body-muscles-small.png') +
            ' 600w, ' +
            cdn('images', 'all-body-muscles-medium.png') +
            ' 800w'
        "
        sizes="(max-width: 600px) 480px, 800px"
        max-width="80%"
        contain
        gradient="to bottom right, rgba(216,27,96,.1), rgb(31, 44, 76, .15)"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular indeterminate color="accent lighten-4" />
          </v-row>
        </template>
      </v-img>
    </v-row>

    <v-card flat class="transparent">
      <v-card-text class="text-center text-uppercase">
        <span class="pa-1" v-for="letter in alphabet" :key="letter">
          <v-btn
            :small="!isPhone"
            :x-small="isPhone"
            class="mt-2"
            @click="reload(letter)"
            :color="color(letter)"
            :title="'Muscles Starting With: ' + letter"
            >{{ letter }}
          </v-btn>
        </span>
      </v-card-text>
      <v-card-text class="pa-0">
        <v-text-field
          dark
          filled
          class="enlarged-input pb-4 px-1"
          clearable
          color="success"
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          :label="'Search ' + muscleList.length + ' muscles'"
          single-line
          hide-details
        />
      </v-card-text>
    </v-card>
    <v-data-iterator
      mobile-breakpoint="0"
      disable-sort
      :loading="!loaded"
      loading-text="Loading... Please wait"
      :items="muscleList"
      class="elevation-0  link noscroll-x px-"
      flat
      :search="search"
      :items-per-page="50"
      hide-default-footer
    >
      <template v-slot:default="props">
        <v-card
          :style="charcoalTileMenu"
          v-for="item in props.items"
          :key="item.id"
          rounded
          flat
          class="px-2 py-2 mb-2 charcoal"
          href
          :to="'muscledetails/' + item.code"
        >
          <v-card-text :class="textSizeSmall + ' paper--text py-0 px-0'">
            {{ item.name }}
          </v-card-text>
          <v-card-actions class="progressActive--text pa-0">
            <span :class="textSizeXSmall">
              {{ item.muscle_group }}
            </span>
            <span :class="textSizeXSmall"> , {{ item.body_position }} </span>
          </v-card-actions>
          <v-card-actions class="py-0 pl-0">
            <span :class="textSizeXSmall + '  silver--text'">
              {{ item.size }} Muscle
            </span>
            <v-spacer />
            <BaseActionButton
              text
              :large="false"
              label="Details"
              icon=""
              class=" charcoal--text"
              color=""
              href
              :to="'muscledetails/' + item.code"
            />
          </v-card-actions>
        </v-card>
      </template>
      <template v-slot:no-data>
        <v-card-text class="py-10 my-10 body-1 paper--text text--darken-1">
          Sorry, we found no exercises matching this criteria.
        </v-card-text>
      </template>
      <template v-slot:no-results>
        <v-card-text class="py-10 my-10 body-1 paper--text text--darken-1">
          Sorry, we found no exercises matching this criteria.
        </v-card-text>
      </template>
    </v-data-iterator>
  </v-container>
</template>

<script>
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'

export default {
  name: 'MusclePedia',
  mixins: [util],
  props: {
    show: {
      type: Boolean
    }
  },
  data() {
    return {
      loaded: false,
      selectedItem: {},
      showDetailsDialog: false,
      showRowInfo: false,
      activity: '',
      added: '',
      search: '',
      itemsPerPageArray: [20, 50, 100],
      itemsPerPage: 50,
      page: 1,
      letter: 'all',
      muscleList: [],
      alphabet: []
    }
  },
  beforeMount() {
    this.loadMuscles()
    this.alphabet = this.getAlphabet
  },
  computed: {
    itemsCount() {
      return this.muscleList.length
    },
    numberOfPages() {
      return Math.ceil(this.muscleList.length / this.itemsPerPage)
    },
    ...appConfig
  },
  methods: {
    color(letter) {
      if (this.letter == letter) return 'accent'
      else return 'paper'
    },
    details(muscle) {
      this.$router.push('muscledetails/' + muscle.code)
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number
    },
    reload(letter) {
      this.letter = letter
      this.loadMuscles()
    },
    saved() {
      this.loadMuscles()
      this.showDetailsDialog = false
    },
    selectItem(value) {
      this.showRowInfo = false
      this.selectedItem = value
    },
    loadMuscles() {
      return axios
        .get(this.baseURL + '/util/muscles/' + this.letter, {})
        .then(response => {
          if (response.status == 200) {
            this.muscleList = response.data.data
            this.loaded = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>
