<template>
  <v-container class="pa-2">
    <v-card
      max-width="800"
      class="charcoal mx-auto d-flex flex-column mt-5 silver--text flat"
    >
      <v-card-title class="paper--text text-h5 px-0 pl-1 pt-0 pb-1">
        Login or Register
        <v-spacer />
      </v-card-title>
      <v-sheet rounded class="charcoal charcoalTileMenu ">
        <v-stepper dark v-model="step" class="transparent pa-0">
          <v-stepper-header class="">
            <v-stepper-step
              color="progressActive"
              :complete="step > 1"
              step="1"
            >
            </v-stepper-step>
            <v-divider />
            <v-stepper-step step="2" color="paper black--text" />
          </v-stepper-header>
          <v-stepper-items class="px-1">
            <!-- Step 1: Enter Email -->
            <v-stepper-content class="pa-0 px-2" step="1">
              <v-card-text class="pa-0">
                <v-spacer />
                <span class="silver--text">Enter your email below</span>
                <v-spacer />
                <v-text-field
                  height="60"
                  dark
                  background-color="charcoal lighten-3"
                  solo
                  clearable
                  single-line
                  v-model="email"
                  :error-messages="emailErrors"
                  label="Email"
                  color="progressActive"
                  ref="email"
                  class="rounded"
                  prepend-inner-icon="mdi-email"
                  @input="$v.email.$touch()"
                  @keydown.enter="getOtp"
                />
                <v-spacer />
                <div class="silver--text caption mt-n5">
                  You will receive a One Time Password (OTP) at the provided
                  email address.
                </div>
              </v-card-text>
              <v-card-actions class="pa-0 my-2">
                <v-spacer />
                <v-btn
                  x-large
                  dark
                  :disabled="this.$v.$anyError || email === ''"
                  title="Login"
                  tonal
                  color="progressActive"
                  @click="getOtp()"
                >
                  <span class="text-h5">Next</span>
                </v-btn>
              </v-card-actions>
            </v-stepper-content>

            <!-- Step 2: Enter OTP -->
            <v-stepper-content step="2" class="px-1 py-0">
              <span class="silver--text caption">
                Enter the OTP sent to the email address you provided.
              </span>
              <form>
                <v-text-field
                  dark
                  height="60"
                  solo
                  background-color="charcoal lighten-2"
                  clearable
                  v-model="otp"
                  ref="otp"
                  color="progressActive"
                  :error-messages="otpErrors"
                  label="OTP (One Time Password)"
                  prepend-inner-icon="mdi-fingerprint"
                  type="number"
                  pattern="[0-9]*"
                  inputmode="decimal"
                  class="rounded"
                  @blur="$v.otp.$touch()"
                  @keydown.enter="login()"
                />
                <v-card-text
                  v-if="rateViolation"
                  class="orange--text pt-0 text-center"
                >
                  Too many login attempts. Please wait a minute.
                </v-card-text>
                <v-card-actions class="pa-0 mb-2">
                  <v-btn text class="ml-n2" color="paper" @click="step = 1">Back</v-btn>
                  <v-spacer />
                  <v-btn
                    x-large
                    tonal
                    dark
                    :disabled="this.$v.$anyError || email === '' || otp === ''"
                    title="Login"
                    color="progressActive"
                    @click="login()"
                  >
                    <span class="text-h5">Login</span>
                  </v-btn>
                </v-card-actions>
              </form>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-sheet>
    </v-card>
  </v-container>
</template>

<script>
import {
  required,
  email,
  minLength,
  maxLength,
  numeric
} from 'vuelidate/lib/validators'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
import axios from 'axios'

export default {
  mixins: [util],
  name: 'LoginPage',
  components: {},
  data() {
    return {
      showotp: false,
      email: '',
      otp: '',
      error: '',
      step: 1,
      rateViolation: false
    }
  },
  validations: {
    email: { required, email },
    otp: { required, numeric, maxLength: maxLength(6), minLength: minLength(6) }
  },
  computed: {
    emailErrors() {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.email && errors.push('')
      !this.$v.email.required && errors.push('')
      return errors
    },
    otpErrors() {
      const errors = []
      if (!this.$v.otp.$dirty) return errors
      !this.$v.otp.required && errors.push('OTP is required')
      !this.$v.otp.numeric && errors.push('OTP must be numeric')
      !this.$v.otp.maxLength && errors.push('OTP must be 6 digits')
      !this.$v.otp.minLength && errors.push('OTP must be 6 digits')
      return errors
    },
    ...appConfig
  },
  methods: {
    getOtp() {
      this.step = 2
      return axios
        .post(this.baseURL + '/accounts/generate/otp', {
          email: this.email
        })
        .then(response => {
          if (response.status == 200) {
            null
          } else {
            this.$store.dispatch('session/login', null)
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },

    login() {
      this.rateViolation = false
      const url = `${this.baseURL}/accounts/otplogin`
      axios
        .post(url, {
          email: this.email,
          otp: this.otp
        })
        .then(response => {
          if (response.status === 200) {
            let accountInfo = response.data.data
            this.$store.dispatch('session/login', accountInfo)

            if (this.isNativeApp()) {
              window.ReactNativeWebView.postMessage(JSON.stringify(accountInfo))
            }

            if (this.loggedIn) {
              this.$store.dispatch('session/loadSubscription', {})
              this.$router.push('/')
            }
          } else {
            this.$store.dispatch('session/login', null)
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              { root: true }
            )
          }
        })
        .catch(error => {
          if (error.message && error.message.includes('Network Error')) {
            this.rateViolation = true
            setTimeout(() => {
              this.rateViolation = false
            }, 3000)
          }
        })
    }
  }
}
</script>
<style scoped>
.v-stepper__header {
  box-shadow: none !important;
}
</style>
