<template>
  <v-container>
    <BaseScreenHeader title="Glossary" screenInfo="GLO-001" :showBack="true" />
    <v-card-actions v-if="xvy" class="pt-0">
      <v-spacer />
      <v-btn
        text
        class="charcoal paper--text"
        title="Add New"
        @click="showAddDialog = true"
      >
        <v-icon>
          mdi-plus-circle-outline
        </v-icon>
        Add
      </v-btn>
    </v-card-actions>
    <v-card flat class="pa-0 transparent mt-4">
      <v-card-text class="text-center text-uppercase pa-0">
        <span class="pa-1" v-for="letter in alphabet" :key="letter">
          <v-btn
            :small="!isPhone"
            :x-small="isPhone"
            class="mt-2"
            @click="reload(letter)"
            :color="color(letter)"
            :title="'Glossary Terms Starting With: ' + letter"
          >
            {{ letter }}
          </v-btn>
        </span>
      </v-card-text>
      <v-card-text class="pb-0 px-0">
        <v-row dense>
          <v-spacer />
          <v-col cols="12">
            <v-text-field
              dark
              clearable
              filled
              color="success"
              class="enlarged-input pb-0 px-3"
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            />
          </v-col>
          <v-col cols="12">
            <v-data-iterator
              hide-default-footer
              mobile-breakpoint="0"
              :headers="myheaders"
              :loading="!loaded"
              loading-text="Loading... Please Wait"
              :items="glossaryList"
              :items-per-page="itemsPerPage"
              class="elevation-0 body-1 charcoal--text link"
              :search="search"
            >
              <template v-slot:default="props">
                <v-card
                  v-for="item in props.items"
                  :key="item.name"
                  rounded
                  :elevation="0"
                  class="pa-2 mx-2 charcoal charcoalTile mb-2"
                >
                  <v-card-actions :class="textSizeSmall + ' paper--text pa-0'">
                    {{ item.title }}
                  </v-card-actions>
                  <v-card-text
                    :class="textSizeXSmall + ' pa-0 silver--text'"
                    v-html="item.details"
                  >
                  </v-card-text>
                  <v-card-text v-if="item.tags != null" class="px-0 py-0 ml-n1">
                    <BaseTag
                      v-for="(tag, index) in item.tags"
                      :key="index"
                      :tagsize="isPhone ? 'small' : 'medium'"
                      icon="mdi-tag-outline"
                      color="transparent paper--text "
                      :title="'Tag: ' + tag.text"
                      :label="tag.text"
                    />
                  </v-card-text>
                  <v-card-actions v-if="canEdit" class="px-0 mt-2 ml-n2">
                    <v-spacer />
                    <v-btn class="silver--text" plain @click="selectItem(item)">
                      <v-icon class="silver--text">
                        mdi-pencil
                      </v-icon>
                      Edit
                    </v-btn>
                    <v-btn class="silver--text" plain @click="tagItem(item)">
                      <v-icon class="silver--text">
                        mdi-tag
                      </v-icon>
                      Tag
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </template>
              <template v-slot:footer>
                <v-card-actions class="px-2 mt-4 pb-0">
                  <span class="silver--text caption">Per page</span>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn dark text color="silver" class="ml-2" v-on="on">
                        {{ itemsPerPage }}
                        <v-icon>mdi-chevron-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(number, index) in itemsPerPageArray"
                        :key="index"
                        @click="updateItemsPerPage(number)"
                      >
                        <v-list-item-title>{{ number }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-spacer />
                  <span class="caption silver--text py-2 px-0">
                    Page {{ page }} of {{ numberOfPages }}
                  </span>
                  <v-btn fab text color="silver" small @click="formerPage">
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-btn
                    fab
                    text
                    color="silver"
                    small
                    class="ml-0  mr-n4"
                    @click="nextPage"
                  >
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </v-card-actions>
              </template>
            </v-data-iterator>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="showEdit"
      :fullscreen="isPhone"
      persistent
      overlay-opacity=".97"
      width="800"
    >
      <v-card class="d-flex flex-column" flat>
        <BaseCloseDialogHeader
          :heading="'Edit ' + selectedForEdit.name"
          @cancel="showEdit = false"
        />
        <v-card-subtitle :class="textSizeXSmall + ' mt-2 py-2'">
          Edit the information below then click "Done".
        </v-card-subtitle>
        <v-card-text>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                v-model="selectedForEdit.name"
                ref="name"
                min="0"
                max="500"
                label="Name"
                prepend-icon="mdi-dots-horizontal-circle"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="selectedForEdit.title"
                ref="title"
                min="0"
                max="500"
                label="Title"
                prepend-icon="mdi-dots-horizontal-circle"
              />
            </v-col>
            <v-col cols="12">
              <tiptap-vuetify
                v-model="selectedForEdit.details"
                :extensions="extensions"
                placeholder="Write details here"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="selectedForEdit.category"
                ref="category"
                min="0"
                max="500"
                label="Category"
                prepend-icon="mdi-dots-horizontal-circle"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="selectedForEdit.icon"
                ref="icon"
                min="0"
                max="500"
                label="Icon"
                prepend-icon="mdi-dots-horizontal-circle"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="selectedForEdit.value"
                ref="value"
                min="0"
                max="500"
                label="Value"
                prepend-icon="mdi-dots-horizontal-circle"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="selectedForEdit.orderby"
                ref="orderby"
                min="0"
                max="500"
                label="Order By"
                type="number"
                prepend-icon="mdi-dots-horizontal-circle"
              />
            </v-col>
            <v-col cols="12">
              <v-spacer />
              <v-switch
                v-model="media"
                label="Show Media Options"
                color="accent"
              />
            </v-col>
            <template v-if="media">
              <v-col cols="12">
                <v-text-field
                  v-model="selectedForEdit.link"
                  ref="link"
                  min="0"
                  max="500"
                  label="Link"
                  prepend-icon="mdi-dots-horizontal-circle"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="selectedForEdit.image"
                  ref="image"
                  min="0"
                  max="500"
                  label="Image"
                  prepend-icon="mdi-dots-horizontal-circle"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="selectedForEdit.video"
                  ref="video"
                  min="0"
                  max="500"
                  label="Video"
                  prepend-icon="mdi-dots-horizontal-circle"
                />
              </v-col>
            </template>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <BaseActionButton
            icon="mdi-close"
            :large="false"
            label="Cancel"
            plain
            title="Cancel Edit"
            color="charcoal"
            @clickedThis="showEdit = false"
          />
          <BaseActionButton
            icon="mdi-checkbox-outline"
            :large="false"
            title="Done Editing"
            label="Done"
            text
            class="charcoal paper--text"
            @clickedThis="done()"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showAddDialog"
      :fullscreen="isPhone"
      persistent
      overlay-opacity=".95"
      width="800"
    >
      <v-card class="d-flex flex-column" flat>
        <BaseCloseDialogHeader
          heading="Add New Term"
          @cancel="showAddDialog = false"
        />
        <v-card-subtitle :class="textSizeXSmall + ' mt-2 py-2'">
          Fill the information below then click "Done".
        </v-card-subtitle>
        <v-card-text>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                v-model="selectedForEdit.name"
                ref="name"
                min="0"
                max="500"
                label="Name"
                prepend-icon="mdi-dots-horizontal-circle"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="selectedForEdit.title"
                ref="title"
                min="0"
                max="500"
                label="Title"
                prepend-icon="mdi-dots-horizontal-circle"
              />
            </v-col>
            <v-col cols="12">
              <tiptap-vuetify
                v-model="selectedForEdit.details"
                :extensions="extensions"
                placeholder="Write details here"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="selectedForEdit.category"
                ref="category"
                min="0"
                max="500"
                label="Category"
                prepend-icon="mdi-dots-horizontal-circle"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="selectedForEdit.icon"
                ref="icon"
                min="0"
                max="500"
                label="Icon"
                prepend-icon="mdi-dots-horizontal-circle"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="selectedForEdit.value"
                ref="value"
                min="0"
                max="500"
                label="Value"
                prepend-icon="mdi-dots-horizontal-circle"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="selectedForEdit.orderby"
                ref="orderby"
                min="0"
                max="500"
                label="Order By"
                type="number"
                prepend-icon="mdi-dots-horizontal-circle"
              />
            </v-col>
            <v-col cols="12">
              <v-spacer />
              <v-switch
                v-model="media"
                label="Show Media Options"
                color="accent"
              />
            </v-col>
            <template v-if="media">
              <v-col cols="12">
                <v-text-field
                  v-model="selectedForEdit.link"
                  ref="link"
                  min="0"
                  max="500"
                  label="Link"
                  prepend-icon="mdi-dots-horizontal-circle"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="selectedForEdit.image"
                  ref="image"
                  min="0"
                  max="500"
                  label="Image"
                  prepend-icon="mdi-dots-horizontal-circle"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="selectedForEdit.video"
                  ref="video"
                  min="0"
                  max="500"
                  label="Video"
                  prepend-icon="mdi-dots-horizontal-circle"
                />
              </v-col>
            </template>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <BaseActionButton
            icon="mdi-close"
            :large="false"
            label="Cancel"
            plain
            title="Cancel Add"
            color="charcoal"
            @clickedThis="showAddDialog = false"
          />
          <BaseActionButton
            icon="mdi-checkbox-outline"
            :large="false"
            title="Done Adding"
            label="Done"
            text
            class="charcoal paper--text"
            @clickedThis="add()"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showTagDialog"
      :fullscreen="isPhone"
      persistent
      overlay-opacity=".95"
      width="800"
    >
      <v-card class="d-flex flex-column" flat>
        <BaseCloseDialogHeader
          :heading="'Manage tags for ' + selectedForEdit.name"
          @cancel="showTagDialog = false"
        />
        <v-card-subtitle :class="textSizeXSmall + ' mt-2 py-2'">
          Choose the right tags then click "Done".
        </v-card-subtitle>
        <v-card-text>
          <v-row dense>
            <v-col cols="12">
              <v-select
                ref="tags"
                v-model="selectedForEdit.tags"
                :items="allTags"
                label="Tags"
                multiple
                chips
                hint="Select Tags"
                persistent-hint
                item-text="text"
                item-value="id"
                return-object
                prepend-icon="mdi-chevron-right-box"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <BaseActionButton
            icon="mdi-close"
            label="Cancel"
            plain
            title="Cancel Add"
            color="charcoal"
            @clickedThis="showTagDialog = false"
          />
          <BaseActionButton
            icon="mdi-checkbox-outline"
            title="Done Adding"
            label="Done"
            text
            class="charcoal paper--text"
            @clickedThis="tag()"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  HardBreak,
  HorizontalRule
} from 'tiptap-vuetify'
export default {
  name: 'GlossaryList',
  components: { TiptapVuetify },
  mixins: [util],
  props: {
    show: {
      type: Boolean
    }
  },
  data() {
    return {
      media: false,
      showEdit: false,
      loaded: false,
      selectedForEdit: {},
      showDetailsDialog: false,
      showAddDialog: false,
      showTagDialog: false,
      showRowInfo: false,
      activity: '',
      added: '',
      search: '',
      letter: 'all',
      glossaryList: [],
      allTags: [],
      glossaryTags: [],
      alphabet: [],
      itemsPerPageArray: [25, 50, 100],
      itemsPerPage: 50,
      page: 1,
      myheaders: [
        {
          text: 'Terms',
          value: 'details',
          class: 'primary--text display-1 pb-2'
        }
      ],
      extensions: [
        Paragraph,
        Bold,
        Italic,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3, 4, 5, 6]
            }
          }
        ],
        HorizontalRule,
        Link,
        ListItem,
        BulletList,
        OrderedList,
        HardBreak
      ]
    }
  },
  beforeMount() {
    this.loadAllTags()
    this.loadGlossary()
    this.alphabet = this.getAlphabet
  },
  computed: {
    itemsCount() {
      return this.glossaryList.length
    },
    numberOfPages() {
      return Math.ceil(this.glossaryList.length / this.itemsPerPage)
    },
    ...appConfig
  },
  methods: {
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number
    },
    color(letter) {
      if (this.letter == letter) return 'accent'
    },
    reload(letter) {
      this.letter = letter
      this.loadGlossary()
    },
    saved() {
      this.loadGlossary()
      this.showDetailsDialog = false
    },
    selectItem(value) {
      this.showEdit = true
      this.selectedForEdit = value
    },
    tagItem(value) {
      this.showTagDialog = true
      this.selectedForEdit = value
    },
    done() {
      return axios
        .put(this.baseURL + '/glossary', {
          glossary: this.selectedForEdit
        })
        .then(response => {
          if (response.status == 200) {
            this.showEdit = false
            this.loadGlossary()
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    add() {
      return axios
        .post(this.baseURL + '/glossary', {
          glossary: this.selectedForEdit
        })
        .then(response => {
          if (response.status == 200) {
            this.showAddDialog = false
            this.loadGlossary()
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    tag() {
      return axios
        .put(this.baseURL + '/glossary/tags', {
          glossary: this.selectedForEdit
        })
        .then(response => {
          if (response.status == 200) {
            this.showTagDialog = false
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadGlossary() {
      return axios
        .get(this.baseURL + '/util/glossary/' + this.letter, {})
        .then(response => {
          if (response.status == 200) {
            this.glossaryList = response.data.data
            this.glossaryList.forEach(element => {
              if (element.tags != null) element.tags = JSON.parse(element.tags)
            })
            this.loaded = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadAllTags() {
      return axios.get(this.baseURL + '/util/tags/all', {}).then(response => {
        if (response.status == 200) {
          this.allTags = response.data.data
        } else {
          this.$store.dispatch(
            'notification/addErrors',
            response.data.errors,
            5000,
            true,
            {
              root: true
            }
          )
          return
        }
      })
    }
  }
}
</script>
