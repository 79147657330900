<template>
  <RegisterDialog />
</template>

<script>
const RegisterDialog = () =>
  import(/* webpackPrefetch: true */ '@/components/account/Register.vue')
import util from '@/mixins/util.js'

export default {
  mixins: [util],
  components: { RegisterDialog },
  data() {
    return {}
  }
}
</script>
