<template>
  <v-card flat tile class="charcoal d-flex flex-column">
    <v-spacer />
    <v-card-text :class="textSizeSmall + ' silver--text'">
      Redirecting...
    </v-card-text>
    <v-spacer />
  </v-card>
</template>
<script>
import util from '@/mixins/util.js'
import { appConfig } from '@/store/helpers.js'
import axios from 'axios'

export default {
  components: {},
  mixins: [util],
  data: () => ({
    loaded: false,
    mode: 'Web'
  }),
  beforeMount() {},
  mounted() {
    this.saveReferer()
  },
  props: {
    referer: String,
    id: String
  },
  beforeDestroy() {},
  computed: {
    ...appConfig
  },
  methods: {
    saveReferer() {
      let path = this.marketingLink

      if (this.isAndroidSimple()) {
        path = this.googlePlayLink
        this.mode = 'Android'
      }
      if (this.isIOSSimple()) {
        path = this.appStoreLink
        this.mode = 'IOS'
      }

      return axios
        .post(this.baseURL + '/workers/qr/referer', {
          referer: this.referer,
          id: this.id,
          mode: this.mode
        })
        .then(response => {
          if (response.status == 200) {
            window.location.href = path
          }
        })
    }
  }
}
</script>
