<template>
  <v-container>
    <v-row
      justify="center"
      v-if="success"
      :class="textSizeSmall + 'silver--text pt-10 mt-10'"
    >
      Your email was succesfully sent. Thank you!
    </v-row>
    <v-card v-else class="px-2 transparent accent--text d-flex flex-column">
      <BaseScreenHeader title="Contact Us" screenInfo="CTC-001" />

      <v-card-text class="py-0">
        <v-text-field
          dark
          :error-messages="nameErrors"
          label="Your Name"
          ref="fname"
          v-model="name"
          required
          type="text"
          :counter="50"
          prepend-icon="mdi-account-circle"
          @input="$v.name.$touch()"
          @blur="nameChanged"
        />
        <v-text-field
          dark
          v-model="email"
          :error-messages="emailErrors"
          label="Your Email"
          ref="email"
          prepend-icon="mdi-email"
          :counter="250"
          @input="$v.email.$touch()"
        />
        <v-text-field
          dark
          v-model="subject"
          :error-messages="subjectErrors"
          label="Subject"
          ref="subject"
          prepend-icon="mdi-text-short"
          :counter="50"
          @input="$v.subject.$touch()"
        />
        <v-textarea
          dark
          v-model="message"
          ref="message"
          :error-messages="messageErrors"
          :counter="2000"
          :rows="5"
          label="Message"
          prepend-icon="mdi-text"
          @input="$v.message.$touch()"
        />
      </v-card-text>
      <v-spacer />
      <v-card-actions class="px-0">
        <v-spacer />

        <BaseActionButton
          dark
          :disabled="this.$v.$anyError || email == ''"
          label="Send Email"
          icon="mdi-submit"
          text
          :large="!isPhone"
          @clickedThis="sendEmail"
        />
      </v-card-actions>
    </v-card>
  </v-container>
</template>
<script>
import { required, email, maxLength, minLength } from 'vuelidate/lib/validators'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
import axios from 'axios'
export default {
  mixins: [util],
  name: 'Contact',
  components: {},
  data() {
    return {
      email: '',
      name: '',
      subject: '',
      message: '',
      success: false,
      sitekey: '6LcGA6saAAAAAEJ6y4eVkseDEz-uTdDVFhQ0ToUp'
    }
  },
  validations: {
    email: { required, email },
    name: {
      required,
      minLength: minLength(1),
      maxLength: maxLength(50)
    },
    subject: {
      required,
      minLength: minLength(5),
      maxLength: maxLength(50)
    },
    message: {
      required,
      minLength: minLength(20),
      maxLength: maxLength(2000)
    }
  },
  computed: {
    emailErrors() {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.email && errors.push('Invalid e-mail')
      !this.$v.email.required && errors.push('E-mail is required')
      return errors
    },
    nameErrors() {
      const errors = []
      if (!this.$v.name.$dirty) return errors
      !this.$v.name.minLength &&
        errors.push('Name must be at least 1 characters long')
      !this.$v.name.maxLength &&
        errors.push('Name must be at most 50 characters long')
      !this.$v.name.required && errors.push('Name is required.')
      return errors
    },
    subjectErrors() {
      const errors = []
      if (!this.$v.subject.$dirty) return errors
      !this.$v.subject.minLength &&
        errors.push('Subject must be between 5 and 50 characters long.')
      !this.$v.subject.maxLength &&
        errors.push('Subject must be between 5 and 50 characters long.')
      !this.$v.subject.required && errors.push('Subject is required.')
      return errors
    },
    messageErrors() {
      const errors = []
      if (!this.$v.message.$dirty) return errors
      !this.$v.message.maxLength &&
        errors.push('Message must be between 20 and 2000 characters long.')
      !this.$v.message.minLength &&
        errors.push('Message must be between 20 and 2000 characters long.')
      !this.$v.message.required && errors.push('Message is required.')
      return errors
    },
    ...appConfig
  },
  mounted() {
    this.$refs.email.focus()
  },
  methods: {
    nameChanged() {
      this.name = this.capitalize(this.name)
    },
    capitalize(what) {
      this[what] = this[what].charAt(0).toUpperCase() + this[what].slice(1)
    },
    sendEmail() {
      return axios
        .put(this.baseURL + '/util/contact', {
          name: this.name,
          email: this.email,
          subject: this.subject,
          message: this.message
        })
        .then(response => {
          if (response.status == 200) {
            this.success = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>
