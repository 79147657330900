<template>
  <v-container>
    <v-card
      :width="isPhone ? '100%' : '50%'"
      class="transparent paper--text d-flex flex-column"
    >
      <v-card-text
        justify="center"
        v-if="success"
        :class="textSizeSmall + 'silver--text mt-10 '"
      >
        You will no longer receive our newsletter!
        <v-spacer />
        To start receiving the newsletter again, please visit your account
        <a href="/profile" title="Account Profile">profile</a>
        and toggle "Receive Email Updates" in the "Communication" section.
      </v-card-text>
      <template v-else>
        <BaseScreenHeader title="Unsubscribe" screenInfo="USB-001" />

        <v-card-text class="silver--text px-2">
          Please enter your account's email below to unsubscribe from our "BFit
          News". Please note that BFit news provides you with the latest updates
          regarding new features as well as tips & tricks, tutorials that teach
          how to make the best use of this application.
        </v-card-text>
        <v-card-text class="pa-0 px-2">
          <v-text-field
            dark
            v-model="email"
            :error-messages="emailErrors"
            label="Your Email"
            ref="email"
            prepend-icon="mdi-email"
            :counter="250"
            @input="$v.email.$touch()"
          />
        </v-card-text>

        <v-card-actions class="px-0">
          <v-spacer />
          <BaseActionButton
            dark
            :disabled="this.$v.$anyError || email == ''"
            label="Unsubscribe"
            icon="mdi-submit"
            text
            :large="!isPhone"
            @clickedThis="unsubscribe"
          />
        </v-card-actions>
      </template>
    </v-card>
  </v-container>
</template>
<script>
import { required, email } from 'vuelidate/lib/validators'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'

import axios from 'axios'
export default {
  mixins: [util],
  name: 'Unsubscribe',
  components: {},
  data() {
    return {
      email: '',
      name: '',
      subject: '',
      message: '',
      success: false,
      sitekey: '6LcGA6saAAAAAEJ6y4eVkseDEz-uTdDVFhQ0ToUp'
    }
  },
  validations: {
    email: { required, email }
  },
  computed: {
    emailErrors() {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.email && errors.push('Invalid e-mail')
      !this.$v.email.required && errors.push('E-mail is required')
      return errors
    },

    ...appConfig
  },
  mounted() {
    this.$refs.email.focus()
  },
  methods: {
    nameChanged() {
      this.name = this.capitalize(this.name)
    },
    unsubscribe() {
      return axios
        .put(this.baseURL + '/util/unsubscribe', {
          email: this.email
        })
        .then(response => {
          if (response.status == 200) {
            this.success = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>
