<template>
  <v-container>
    <BaseScreenHeader
      title="Muscle Details"
      screenInfo="MSP-002"
      :showBack="true"
      :showNav="true"
    />
    <v-card tile flat v-if="loaded" class="transparent px-0 mt-2">
      <MuscleDisplay :code="$route.params.code" :exercises="exercises" />
    </v-card>
  </v-container>
</template>

<script>
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
const MuscleDisplay = () =>
  import(/* webpackPrefetch: true */ '@/components/pageutil/MuscleDisplay.vue')
export default {
  components: { MuscleDisplay },
  name: 'MuscleDetails',
  mixins: [util],
  data() {
    return {
      loaded: false,
      exercises: [],
      muscle: {}
    }
  },
  beforeMount() {
    this.loadExercises()
  },
  computed: {
    ...appConfig
  },
  methods: {
    loadExercises() {
      return axios
        .get(
          this.baseURL + '/util/muscleexercises/' + this.$route.params.code,
          {}
        )
        .then(response => {
          if (response.status == 200) {
            let rownum = 0
            this.exercises = response.data.data
            this.exercises.forEach(el => {
              el.rownum = rownum++
            })
            this.loaded = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>
