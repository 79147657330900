<template>
  <v-container class="mt-5">
    <BaseScreenHeader title="User Profile" screenInfo="PRO-002" width="90%" />
    <v-card rounded flat class="pt-2 px-2 charcoal  mt-5" v-if="loaded">
      <v-row dense>
        <v-col cols="4" md="2">
          <v-avatar
            :title="profile.display_name"
            color="paper"
            :size="isPhone ? 100 : 150"
            tile
          >
            <v-img
              v-if="profile.picture_cdn != null"
              :alt="profile.display_name"
              :src="resizedName(profile.picture_cdn, '150')"
              contain
              :width="isPhone ? 100 : 150"
            />
            <span :class="textSizeLarge + '  silver--text'" v-else>
              {{ profile.display_name.substring(0, 2).toUpperCase() }}
            </span>
          </v-avatar>
        </v-col>
        <v-col cols="8" md="10">
          <v-badge
            :content="followed ? 'Following' : 'Not Following'"
            color="charcoal lighten-2"
            offset-x="10"
            offset-y="5"
            left
          >
            <span :class="textSizeLarge + ' silver--text'">
              {{ profile.display_name }}
            </span>
          </v-badge>
          <template v-if="loggedIn">
            <BaseActionButton
              v-if="!followed"
              label="Follow"
              class="caption mt-n2 "
              text
              color="progressActive"
              :large="!isPhone"
              icon="mdi-account-star"
              @clickedThis="follow"
            />
            <template v-if="followed">
              <BaseActionButton
                v-if="followed"
                label="UnFollow"
                class="caption silver--text mt-n2"
                text
                :large="!isPhone"
                icon="mdi-account-remove"
                @clickedThis="unfollow"
              />
            </template>
          </template>
          <template v-else>
            <div
              class="primary--text ml-5 mb-5"
              @click="$router.push('/login')"
              style="cursor: pointer"
            >
              Login or Register to follow.
            </div>
          </template>
        </v-col>
      </v-row>
      <v-row dense>
        <v-spacer />
        <v-col cols="12" md="10">
          <div
            class=" mt-n1 caption charcoal--text text--lighten-3 font-italic"
          >
            Member since: {{ prettyDate(profile.member_since) }}
          </div>
          <div class="body-1 silver--text">
            <span v-if="profile.sex != null">{{ profile.sex }},</span>
            {{ profile.age }}
            <span v-if="profile.age != null">years old</span>
          </div>
          <template v-if="profile.show_basic_stats == 'Yes'">
            <div class="caption silver--text ml-2">
              Height: {{ feet }} feet & {{ inches }} inches or
              {{ basicMetric.height }} cm
            </div>
            <div
              v-if="profile.show_basic_stats"
              class="caption silver--text ml-2"
            >
              Weight: {{ basicImperial.weight }} lbs or
              {{ basicMetric.weight }} kg
            </div>
            <div
              v-if="profile.show_basic_stats && basicImperial.bfp > 0"
              class="caption silver--text ml-2"
            >
              Body Fat: {{ basicImperial.bfp }} %
            </div>
            <div class="title paper--text pt-2">
              &#8221;{{ profile.tagline }}&#8220;
            </div>
          </template>
        </v-col>
      </v-row>
    </v-card>
    <v-spacer />
    <v-card rounded flat class="pt-2 px-0 charcoal darken-1 mt-5 ">
      <v-card-actions class="charcoal mt- pa-0">
        <v-btn
          v-if="profile.website != null && profile.website != ''"
          class="silver--text"
          large
          icon
          title="Website"
          :href="profile.website"
          target="_blank"
        >
          <v-icon>mdi-earth</v-icon></v-btn
        >
        <v-btn
          class="silver--text"
          v-if="profile.instagram != '' && profile.instagram != null"
          large
          icon
          title="Instagram"
          :href="profile.instagram"
          target="_blank"
        >
          <v-icon>mdi-instagram</v-icon></v-btn
        >
        <v-btn
          v-if="profile.facebook != '' && profile.facebook != null"
          class="silver--text"
          large
          icon
          title="Facebook"
          :href="profile.facebook"
          target="_blank"
        >
          <v-icon>mdi-facebook</v-icon></v-btn
        >
        <v-btn
          v-if="profile.linkedin != '' && profile.linkedin != null"
          class="silver--text"
          large
          icon
          title="LinkedIn"
          :href="profile.linkedin"
          target="_blank"
        >
          <v-icon>mdi-linkedin</v-icon></v-btn
        >
        <v-btn
          v-if="profile.youtube != '' && profile.youtube != null"
          class="silver--text"
          large
          icon
          title="Youtube"
          :href="profile.youtube"
          target="_blank"
        >
          <v-icon>mdi-youtube</v-icon></v-btn
        >
        <v-btn
          v-if="
            profile.tiktok && profile.tiktok != '' && profile.tiktok != null
          "
          class="silver--text"
          large
          icon
          title="TikTok"
          :href="profile.tiktok"
          target="_blank"
        >
          <v-icon>mdi-alpha-k-circle</v-icon></v-btn
        >
        <v-btn
          v-if="profile.snapchat != '' && profile.snapchat != null"
          class="silver--text"
          large
          icon
          title="Snapchat"
          :href="'https://www.snapchat.com/add/' + profile.snapchat"
          target="_blank"
        >
          <v-icon>mdi-snapchat</v-icon>
        </v-btn>
        <v-btn
          v-if="profile.twitter != '' && profile.twitter != null"
          class="silver--text"
          large
          icon
          title="Twitter"
          :href="'https://twitter.com/' + profile.twitter"
          target="_blank"
        >
          <v-icon>mdi-twitter</v-icon></v-btn
        >
      </v-card-actions>
      <v-sheet rounded :class="textSize + ' charcoal silver--text pa-2'">
        <span class="paper--text">
          About Me
        </span>
        <v-card-text :class="textSizeSmall + ' pa-0'" v-html="profile.about">
        </v-card-text>
      </v-sheet>
      <v-sheet class="charcoal paper--text mt-2" rounded>
        <v-card-actions
          v-if="strengthNumber.myrank > 0"
          :class="textSizeXLarge + ' silver--text px-0 pb-0'"
        >
          <v-spacer />
          RANK
          <span class="progressActive--text ml-2">{{
            strengthNumber.myrank
          }}</span>
          <v-spacer />
        </v-card-actions>
        <v-card-actions
          v-if="strengthNumber.strength_number > 0"
          :class="textSize + ' charcoal--text text--lighten-3  pb-0 px-0'"
        >
          <v-spacer />
          STRENGTH NUMBER
          <v-spacer />
        </v-card-actions>
        <v-card-actions
          v-if="strengthNumber.strength_number > 0"
          :class="textSizeSmall + ' charcoal--text  py-0 px-0'"
        >
          <v-spacer />
          <span :class="textSizeLarge + ' silver--text'">
            {{ oneDigit(strengthNumber.strength_number) }}%
          </span>
          <v-spacer />
        </v-card-actions>
      </v-sheet>
      <v-row dense class="mt-4">
        <v-col cols="12" md="6">
          <v-card
            rounded
            class="charcoal"
            v-if="powerlifting != null && powerlifting.imperial > 0"
          >
            <v-card-title :class="textSize + ' paper--text pl-2 pb-0 pt-2'">
              <v-spacer />
              Powerlifting Total
              <v-spacer />
            </v-card-title>
            <v-card-text :class="textSizeSmall + ' px-2 pt-0 silver--text'">
              <span :class="textSizeLarge + ' progressActive--text'">
                {{ Math.ceil(powerlifting.imperial) }} lbs /
                {{ Math.ceil(powerlifting.metric) }} kg</span
              >
              <v-spacer />
              Body Weight Total: {{ powerlifting.bw_sum }} SELFS<br />
              Body Weight Average: {{ powerlifting.bw_avg }} SELFS
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card
            rounded
            class="charcoal"
            v-if="cft != null && cft.imperial > 0"
          >
            <v-card-title :class="textSize + ' paper--text pl-2 pb-0 pt-2'">
              <v-spacer />
              CFT(Crossfit) Total
              <v-spacer />
            </v-card-title>
            <v-card-text :class="textSizeSmall + ' px-2 pt-0 silver--text'">
              <span :class="textSizeLarge + ' progressActive--text'">
                {{ Math.ceil(cft.imperial) }} lbs /
                {{ Math.ceil(cft.metric) }} kg</span
              >
              <v-spacer />
              Body Weight Total: {{ cft.bw_sum }} SELFS<br />
              Body Weight Average: {{ cft.bw_avg }} SELFS
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <template v-if="profile.show_lifting_stats == 'Yes'">
        <v-card-title :class="textSize + ' paper--text pb-0 px-0'">
          <v-divider class="charcoal" />
          <span class="mx-2">
            Strongest Lifts
          </span>
          <v-divider class="charcoal" />
        </v-card-title>
        <v-row dense>
          <v-col cols="12">
            <v-card
              @click="$router.push('/exercises/' + exercise.exerciseid)"
              :title="'View Exercise: ' + exercise.name"
              v-for="(exercise, index) in bigLifts"
              :key="exercise.exerciseid"
              class="pb-1 mb-2 charcoal silver--text"
              rounded
            >
              <v-card-text class="body-1 paper--text pl-1">
                <v-icon large class="mr-1 silver--text">
                  {{ iconNumber(index) }}
                </v-icon>
                {{ exercise.name }}
              </v-card-text>
              <v-sheet class="transparent">
                <v-card-actions
                  :class="textSize + ' pt-1 progressActive--text pb-0'"
                >
                  <v-spacer />
                  <span class="pr-2 progressActive--text">
                    {{ Math.ceil(exercise.imperial) }} lbs /
                    {{ Math.ceil(exercise.metric) }} kg
                  </span>
                </v-card-actions>
                <v-card-actions
                  :class="textSizeXSmall + ' silver--text pt-0 pb-1'"
                >
                  <v-spacer />
                  <span class="pr-2 --text">
                    <span class="charcoal--text text--lighten-2 mr-2"
                      >In Body Weight</span
                    >{{ exercise.bwratio }} SELFS
                  </span>
                </v-card-actions>
              </v-sheet>
            </v-card>
          </v-col>
        </v-row>
      </template>
      <template v-if="profile.show_lifting_stats == 'Yes'">
        <v-card-title :class="textSize + ' paper--text pb-0 px-0'">
          <v-divider class="charcoal" />
          <span class="mx-2">
            Top Exercises
          </span>
          <v-divider class="charcoal" />
        </v-card-title>
        <v-row dense>
          <v-col cols="12">
            <v-card
              @click="$router.push('/exercises/' + exercise.exerciseid)"
              :title="'View Exercise: ' + exercise.name"
              v-for="(exercise, index) in topExercises"
              :key="exercise.exerciseid"
              class="pb-1 mb-2 charcoal silver--text"
              rounded
            >
              <v-card-text class="body-1 paper--text pl-1">
                <v-icon large class="mr-1 silver--text">
                  {{ iconNumber(index) }}
                </v-icon>
                {{ exercise.name }}
              </v-card-text>
              <v-sheet class="charcoal">
                <v-card-actions
                  :class="textSize + ' progressActive--text pt-1 pb-0'"
                >
                  <v-spacer />
                  <span class="pr-1"> {{ exercise.sets }} sets </span>
                </v-card-actions>
                <v-card-actions
                  :class="textSizeXSmall + ' silver--text pt-0 pb-1'"
                >
                  Volume:
                  <v-spacer />
                  <span class="pr-1 silver--text">
                    {{ parseInt(exercise.volume_imperial).toLocaleString() }}
                    lbs /
                    {{ parseInt(exercise.volume_metric).toLocaleString() }}
                    kg
                  </span>
                </v-card-actions>
              </v-sheet>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-card>

    <BaseSnackBar :show="snackbar" :snack="snackText" />
  </v-container>
</template>

<script>
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'

export default {
  mixins: [util],
  props: {
    show: {
      type: Boolean
    },
    profileid: {
      type: String
    }
  },

  data() {
    return {
      loaded: false,
      topExercises: [],
      bigLifts: [],
      followed: false,
      profile: {},
      basicImperial: {},
      basicMetric: {},
      feet: '',
      inches: '',
      timeout: 1000,
      snackText: '',
      snackbar: false,
      tab: null,
      strengthNumber: {},
      userStrengthNumbers: [],
      userStrengthExercises: [],
      powerliftingExercises: [],
      cftExercises: [],
      powerlifting: {},
      cft: {}
    }
  },
  beforeMount() {
    this.reload()
  },
  computed: {
    ...appConfig
  },
  watch: {
    show: function(value) {
      if (value) this.reload()
      else this.reset()
    }
  },

  methods: {
    reload() {
      this.loadProfile()
      this.loadBasicImperial()
      this.loadBasicMetric()
      this.loadTopExercises()
      this.loadBigLifts()
      this.loadStrengthNumber()
    },
    reset() {
      this.loaded = false
    },
    follow() {
      return axios
        .post(this.baseURL + '/user/follow/' + this.profileid, {})
        .then(response => {
          if (response.status == 200) {
            this.followed = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    unfollow() {
      return axios
        .post(this.baseURL + '/user/unfollow/' + this.profileid, {})
        .then(response => {
          if (response.status == 200) {
            this.followed = false
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    isFollowing() {
      return axios
        .get(this.baseURL + '/user/isfollowing/' + this.profileid, {})
        .then(response => {
          if (response.status == 200) {
            this.followed = !response.data.data
            this.loaded = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadProfile() {
      return axios
        .get(this.baseURL + '/user/public/profile/' + this.profileid, {})
        .then(response => {
          if (response.status == 200) {
            this.profile = response.data.data
            if (this.loggedIn) this.isFollowing()
            else this.loaded = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadBasicMetric() {
      return axios
        .get(
          this.baseURL + '/user/public/basic/' + this.profileid + ':metric',
          {}
        )
        .then(response => {
          if (response.status == 200) {
            this.basicMetric = response.data.data
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadBasicImperial() {
      return axios
        .get(
          this.baseURL + '/user/public/basic/' + this.profileid + ':imperial',
          {}
        )
        .then(response => {
          if (response.status == 200) {
            this.basicImperial = response.data.data
            this.feet = Math.floor(this.basicImperial.height / 12)
            this.inches = this.basicImperial.height % 12
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadTopExercises() {
      return axios
        .get(this.baseURL + '/user/public/topexercises/' + this.profileid, {})
        .then(response => {
          if (response.status == 200) {
            this.topExercises = response.data.data
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadBigLifts() {
      return axios
        .get(this.baseURL + '/user/public/biglifts/' + this.profileid, {})
        .then(response => {
          if (response.status == 200) {
            this.bigLifts = response.data.data
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadStrengthNumber() {
      {
        return axios
          .get(
            this.baseURL + '/stats/strength/public/number/' + this.profileid,
            {}
          )
          .then(response => {
            if (response.status == 200) {
              this.strengthNumber = response.data.data
              this.loadUserStrengthNumbers()
              this.loadUserStrengthExercises()
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    loadUserStrengthExercises() {
      {
        return axios
          .get(
            this.baseURL + '/stats/strength/public/exercises/' + this.profileid,
            {}
          )
          .then(response => {
            if (response.status == 200) {
              this.userStrengthExercises = response.data.data
              this.powerliftingExercises = this.userStrengthExercises.filter(
                function(item) {
                  return item.powerlifting == 'Yes'
                }
              )
              this.cftExercises = this.userStrengthExercises.filter(function(
                item
              ) {
                return item.cft == 'Yes'
              })
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    loadUserStrengthNumbers() {
      {
        return axios
          .get(
            this.baseURL + '/stats/strength/public/numbers/' + this.profileid,
            {}
          )
          .then(response => {
            if (response.status == 200) {
              this.userStrengthNumbers = response.data.data
              this.powerlifting = this.userStrengthNumbers.filter(function(
                item
              ) {
                return item.type == 'Powerlifting'
              })[0]
              this.cft = this.userStrengthNumbers.filter(function(item) {
                return item.type == 'CFT'
              })[0]
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    }
  }
}
</script>
