<template>
  <v-card
    max-width="600"
    class="mx-auto mt-10 charcoal--text d-flex flex-column"
  >
    <v-card-text :class="textSizeLarge + ' pl-4 pt-2 pb-3 lightHeader'">
      Forgot Password?
    </v-card-text>
    <v-card-text class="caption silver--text">
      This site is protected by Google's reCAPTCHA. See their
      <a class="caption silver--text" href="https://policies.google.com/privacy"
        >Privacy Policy</a
      >
      and
      <a class="caption silver--text" href="https://policies.google.com/terms"
        >Terms of Service</a
      >.
    </v-card-text>
    <template v-if="success">
      <h5 class="px-4 pb-10">Password succesfully changed.</h5>
      <v-spacer />
      <v-card-actions :class="textSizeXSmall + ' mt-10 px-4 silver--text'">
        <span class="link" @click="$router.push('/login')">
          Please Login.
        </span>
        <v-spacer />
        <BaseActionButton
          label="Login"
          text
          dark
          class="charcoal paper--text"
          icon="mdi-login-variant"
          @clickedThis="$router.push('/login')"
        />
      </v-card-actions>
    </template>
    <template v-else>
      <v-card-text>
        <template v-if="!codeSent">
          <v-card-text :class="textSizeXSmall + ' pt-0 px-0'">
            Enter your email below and click "Send". If an account associated
            with the entered email exists, you will shortly receive a
            verification code.
          </v-card-text>
          <v-text-field
            v-model="email"
            type="email"
            :error-messages="emailErrors"
            label="Email"
            ref="email"
            prepend-icon="mdi-email"
            :counter="250"
            @input="$v.email.$touch()"
            @blur="$v.email.$touch()"
          />
        </template>
        <template v-else>
          <v-card-text :class="textSizeXSmall + ' pt-0 px-0'">
            To change your password, please enter the verification code received
            via email.
          </v-card-text>
          <v-text-field
            v-model="verification"
            type="number"
            ref="verification"
            :counter="6"
            label="Verification Code"
            prepend-icon="mdi-fingerprint"
          />

          <v-text-field
            :error-messages="passwordErrors"
            :type="showPassword ? 'text' : 'password'"
            :counter="250"
            name="password"
            label="Password"
            v-model="password"
            prepend-icon="mdi-lock"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            @input="$v.password.$touch()"
            @blur="$v.password.$touch()"
          />
          <v-text-field
            :error-messages="repeatPasswordErrors"
            :type="showPassword ? 'text' : 'password'"
            :counter="250"
            name="password2"
            label="Repeat Password"
            v-model="password2"
            prepend-icon="mdi-lock"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="showPassword = !showPassword"
            @input="$v.password2.$touch()"
            @blur="$v.password2.$touch()"
          />
        </template>
      </v-card-text>
      <v-spacer />
      <v-card-actions>
        <v-spacer />
        <BaseActionButton
          v-if="!codeSent"
          :disabled="this.$v.$anyError || email == ''"
          label="Send"
          text
          dark
          class="charcoal paper--text"
          icon="mdi-login-variant"
          @clickedThis="send"
        />
        <BaseActionButton
          v-if="codeSent"
          :disabled="this.$v.$anyError || !(verification.length == 6)"
          label="Change Password"
          text
          dark
          class="charcoal paper--text"
          icon="mdi-login-variant"
          @clickedThis="changePassword"
        />
      </v-card-actions>
    </template>
  </v-card>
</template>

<script>
import {
  required,
  maxLength,
  minLength,
  sameAs,
  email
} from 'vuelidate/lib/validators'
import { appConfig } from '@/store/helpers.js'
import { isPasswordValid } from '@/validators'
import util from '@/mixins/util.js'
import axios from 'axios'
export default {
  mixins: [util],
  name: 'ForgotPassword',
  components: {},
  data() {
    return {
      success: false,
      showPassword: false,
      showverification: false,
      codeSent: false,
      email: '',
      phone: '',
      password: '',
      password2: '',
      verification: '',
      error: '',
      response: {},
      sitekey: '6LcGA6saAAAAAEJ6y4eVkseDEz-uTdDVFhQ0ToUp'
    }
  },
  validations: {
    email: { required, email },
    verification: { required },
    password: {
      required,
      minLength: minLength(8),
      maxLength: maxLength(250),
      isPasswordValid: isPasswordValid
    },
    password2: {
      sameAsPassword: sameAs('password')
    }
  },
  computed: {
    emailErrors() {
      const errors = []
      if (!this.$v.email.$dirty) return errors
      !this.$v.email.email && errors.push('Invalid e-mail')
      !this.$v.email.required && errors.push('E-mail is required')
      return errors
    },
    passwordErrors() {
      const errors = []
      if (!this.$v.password.$dirty) return errors
      !this.$v.password.required && errors.push('Password is required')
      !this.$v.password.isPasswordValid &&
        errors.push(
          'Invalid password. Password must be: minimum 12 characters long, maximum 250 long and contain at least one uppercase letter, one lowercase letter and one number. '
        )
      return errors
    },
    repeatPasswordErrors() {
      const errors = []
      if (!this.$v.password2.$dirty) return errors
      !this.$v.password2.sameAsPassword &&
        errors.push('Repeat password should be the same as password.')
      return errors
    },
    ...appConfig
  },
  methods: {
    send() {
      return axios
        .post(this.baseURL + '/accounts/password/forgot', {
          email: this.email,
          phone: this.phone,
          type: 'reset_byemail'
        })
        .then(response => {
          if (response.status == 200) {
            this.toast('Verification code sent. Check your email.')
            this.codeSent = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              {
                root: true
              }
            )
            return
          }
        })
    },
    changePassword() {
      return axios
        .post(this.baseURL + '/accounts/password/change', {
          email: this.email,
          verification: this.verification,
          type: 'reset_byemail',
          password: this.password,
          password2: this.password2
        })
        .then(response => {
          if (response.status == 200) {
            this.success = true
            this.toast('Your password was succesfully changed.')
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>
