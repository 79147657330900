<template>
  <v-container>
    <BaseScreenHeader
      title="Public Workouts"
      screenInfo="WRK-007"
      :showBack="true"
    />

    <v-sheet class="charcoal">
      <v-card-text class="text-center pb-0 pt-2 mt-2">
        <span title="Active Filter" :class="textSizeSmall + ' silver--text'">
          {{ workoutType }}
        </span>
      </v-card-text>
      <v-sheet color="transparent">
        <v-card-text class="text-center py-0 mb-1">
          <v-icon
            large
            title="ALL"
            color="silver"
            @click="filterWorkouts('All')"
          >
            mdi-checkbox-multiple-blank
          </v-icon>
          <v-icon
            v-for="(item, index) in typesList"
            :key="index"
            large
            :title="'Workout Type: ' + item.name"
            :color="workoutTypeColor(item)"
            @click="filterWorkouts(item.name)"
          >
            mdi-checkbox-blank
          </v-icon>
        </v-card-text>
      </v-sheet>
      <v-sheet rounded class="transparent py-1 px-0" :style="charcoalTileMenu">
        <v-card-actions class="px-2 mt-2 py-0 mb-n3">
          <v-autocomplete
            dark
            v-model="muscleGroup"
            filled
            color="success"
            item-color="success"
            chips
            return-object
            prepend-inner-icon="mdi-arm-flex"
            :items="muscleGroups"
            label="Muscle Focus"
            outlined
            item-text="muscle_group"
            item-value="muscle_group"
            @change="searchMuscleGroup"
            :menu-props="{
              closeOnContentClick: true,
              closeOnClick: true,
              maxHeight: 250,
              transition: 'fab-transition'
            }"
          />
        </v-card-actions>
        <v-card-actions class="pl-2 pr-1 pt-0">
          <v-text-field
            dark
            filled
            clearable
            :color="!loaded ? 'accent' : 'success'"
            :label="'Search workouts'"
            v-model="search"
            prepend-inner-icon="mdi-weight-lifter"
            single-line
            hide-details
            @focus="$event.target.select()"
            @click:clear="debouncedSearch"
            @keydown.enter="debouncedSearch"
          />
          <v-icon
            color="paper"
            x-large
            title="Search Workouts"
            @click="debouncedSearch"
          >
            mdi-magnify
          </v-icon>
        </v-card-actions>
      </v-sheet>
      <BaseLoading :loaded="loaded" class="mt-6" />

      <template v-if="workouts.length > 0">
        <v-row dense class="mt-4">
          <v-col
            class="mt-2"
            v-for="workout in workouts"
            :key="workout.id"
            cols="12"
            md="6"
          >
            <v-card
              elevation="1"
              rounded
              class="transparent silver--text px-0 mx-1 mb-2"
              :style="charcoalTileMenu"
            >
              <v-sheet
                v-if="loggedIn"
                rounded
                class="transparent py-2 pr-2 mb-2 charcoalTileMenu"
              >
                <v-card-actions class="px-0 pb-0">
                  <BaseActionButton
                    :large="!isPhone"
                    icon="mdi-pencil-box-multiple-outline"
                    :title="'Copy Workout: ' + workout.name"
                    label="Copy"
                    text
                    color=""
                    :class="textSize + ' paper--text'"
                    @clickedThis="copyWorkout(workout)"
                  />
                  <v-spacer />
                  <BaseActionButton
                    :large="!isPhone"
                    icon="mdi-play-box-outline"
                    :title="'Start Workout: ' + workout.name"
                    label="Start Workout"
                    text
                    color=""
                    :class="textSize + ' ' + workoutTypeColor(workout)"
                    @clickedThis="start(workout)"
                  />
                </v-card-actions>
              </v-sheet>
              <v-card-actions :class="textSizeXSmall + ' '" v-else>
                <a
                  href="/login"
                  class="progressActive--text mr-1"
                  title="Login to BFit"
                  >Login</a
                >
                or
                <a
                  class="progressActive--text mx-1"
                  href="/register"
                  title="Register a BFit account"
                >
                  Register
                </a>
                to use this workout.
                <v-spacer />
              </v-card-actions>
              <v-sheet color="transparent" class="silver--text">
                <v-card-title :class="textSize + ' paper--text pl-1 pb-0 pt-2'">
                  <span class="pl-1">{{ trimName(workout) }}</span>
                </v-card-title>
                <v-card-actions
                  :class="textSizeXSmall + ' silver--text py-0 px-2'"
                >
                  Used {{ workout.counter }} times, copied
                  {{ workout.copied }} times.
                  <v-spacer />
                  <span
                    title="Workout Type"
                    :class="textSizeXSmall + ' silver--text py-0 px-0'"
                  >
                    {{ workout.type }}
                    <span
                      class="text-uppercase"
                      v-if="workout.type == 'Hybrid'"
                    >
                      - {{ workout.xmode }}
                    </span>
                  </span>
                </v-card-actions>
                <v-card-actions class="pb-0">
                  <v-divider class="charcoal lighten-1" />
                  <v-badge
                    color="progressActive charcoal--text"
                    inline
                    :content="badgeContent(workout)"
                  >
                  </v-badge>
                  <v-divider class="charcoal lighten-1" />
                </v-card-actions>
                <v-card-actions class="pb-0">
                  <v-spacer />
                  <v-icon large color="silver">
                    mdi-focus-field
                  </v-icon>
                  FOCUS :
                  {{ workout.muscle_group }}
                  <v-spacer />
                </v-card-actions>
                <v-card-text
                  :class="textSizeXSmall + ' silver--text py-0 pl-2'"
                >
                  {{ workout.description }}
                </v-card-text>
                <v-card-text class="transparent pl-0 pb-0">
                  <v-list dense subheader class=" transparent pt-0 pb-0">
                    <v-card-text
                      v-for="exercise in workout.exercises"
                      :key="workout.id + exercise.id + exercise.sort"
                      class="pa-0 mb-1"
                    >
                      <v-badge
                        class="pa-0"
                        color="progressActive charcoal--text"
                        left
                        inline
                        :content="exercise.sort"
                      >
                        <span
                          :class="textSizeSmall + ' paper--text ml-1 link '"
                          :title="'View Details for ' + exercise.name"
                          @click="showExercise(exercise.id)"
                        >
                          {{ exercise.name }}
                        </span>
                      </v-badge>
                      <v-card-actions
                        class="pa-0 ml-6 charcoal--text text--lighten-3"
                      >
                        <template v-if="loaded && exercise.resistance == 'Yes'">
                          <BaseActionButton
                            :label="repSchemeName(exercise.repschemeid)"
                            plain
                            color="silver"
                            small
                            :large="!isPhone"
                            icon="mdi-information-outline"
                            @clickedThis="selectRepScheme(exercise.repschemeid)"
                          />
                          <span class="--text"> - </span>
                        </template>
                        <span
                          class="caption charcoal--text text--lighten-3 ml-2"
                        >
                          {{ exerciseInfo(exercise) }}
                        </span>
                        <v-spacer />
                      </v-card-actions>
                    </v-card-text>
                  </v-list>
                </v-card-text>
                <v-card-actions
                  class="pa-0 link"
                  @click="$router.push('/profile/' + workout.profileid)"
                >
                  <span class="silver--text">
                    <BaseTag
                      color="transparent silver--text"
                      tagsize="medium"
                      title="Author"
                      icon="mdi-account-star"
                      :label="workout.display_name"
                    />
                  </span>
                  <v-spacer />
                </v-card-actions>
              </v-sheet>
            </v-card>
          </v-col>
        </v-row>
      </template>
      <v-card-text align="center" v-else class="silver--text px-2">
        No results matched your search criteria.
      </v-card-text>
    </v-sheet>

    <v-dialog
      v-model="showCopyDialog"
      persistent
      width="1024"
      :fullscreen="isPhone"
      overlay-color="charcoal"
      overlay-opacity="0.97"
    >
      <v-card class="d-flex flex-column charcoal">
        <BaseCloseDialogHeader
          heading="Copy Workout?"
          @cancel="showCopyDialog = false"
        />
        <v-card-text class="silver--text py-0 px-2 ">
          This allows you to copy a public workout to your workout list. You
          will then be able to customize it and use it as you see fit.
          <v-card-subtitle class="title paper--text px-0">
            Current Workout Name
            <div
              class="body-1 silver--text"
              v-html="selectedWorkout.name"
            ></div>
          </v-card-subtitle>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                filled
                dark
                v-model="selectedWorkout.newname"
                ref="name"
                :counter="50"
                label="New Workout Name"
                prepend-inner-icon="mdi-rename-box"
              />
            </v-col>
          </v-row>
          <v-card-subtitle class="body-1 pt-5 px-0">
            Update the workout name above and then click the
            <span class="progressActive--text">Copy </span> button.
          </v-card-subtitle>
        </v-card-text>
        <v-spacer />
        <v-card-actions class="px-0">
          <BaseActionButton
            icon="mdi-close"
            label="Cancel"
            text
            color="silver"
            @clickedThis="showCopyDialog = false"
          />
          <v-spacer />
          <BaseActionButton
            :disabled="isEmpty(selectedWorkout.newname)"
            label="Copy"
            text
            color="paper"
            icon="mdi-pencil-box-multiple-outline"
            @clickedThis="copyNow()"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showRepSchemeDialog"
      persistent
      width="1024"
      :fullscreen="isPhone"
      overlay-color="charcoal"
      overlay-opacity="0.97"
    >
      <v-card class="d-flex flex-column charcoal noscroll-x" tile flat>
        <BaseCloseDialogHeader
          :heading="'Details: ' + selectedRepScheme.name"
          @cancel="showRepSchemeDialog = false"
        />
        <template>
          <v-card-text class="px-2 silver--text">
            <v-divider class="pb-1" />
            <v-card-title class="paper--text pb-0 px-0">
              Info
            </v-card-title>
            <v-card-text :class="textSizeXSmall + ' pb-0'">
              {{ selectedRepScheme.display_name }}
              <br />
              Utility:{{ selectedRepScheme.utility }}<br />
              Scheme Type: {{ selectedRepScheme.type }}
            </v-card-text>
            <v-card-title class="paper--text px-0 py-0">
              Sets: {{ selectedRepScheme.sets }}
            </v-card-title>
            <v-card-text
              class="pl-4 py-0"
              v-for="set in selectedRepScheme.detailsObject"
              :key="set.setnum"
            >
              <v-icon class="progressActive--text pr-2">
                {{ iconNumber(set.setnum - 1) }}
              </v-icon>

              Reps: {{ set.reps }}, Rest: {{ set.rest }} seconds
              <v-spacer />
              @
              <template v-if="set.load_type == '1RM'">
                {{ set.load }}% {{ set.load_type }}
              </template>
              <template v-else>
                {{ set.load_imperial }} lbs / {{ set.load_metric }} kg
              </template>
              + {{ set.load_increment }} lbs/kg
            </v-card-text>
            <template v-if="selectedRepScheme.description != null">
              <v-card-title class="paper--text pb-0 px-0">
                Description
              </v-card-title>
              <v-card-text v-html="selectedRepScheme.description">
              </v-card-text>
            </template>
          </v-card-text>
        </template>
      </v-card>
    </v-dialog>
    <v-dialog
      :width="dialogWidth"
      v-model="showExerciseInfo"
      :fullscreen="isPhone"
      overlay-opacity="0.97"
      :key="switchNum"
    >
      <ExerciseInfo
        :exerciseid="selectedExercise"
        @keydown.esc="cancelInfo"
        @cancel="cancelInfo"
        :key="switchNum"
        @swipe_off="cancelInfo"
      />
    </v-dialog>
  </v-container>
</template>
<script>
import axios from 'axios'
import { appConfig, builder } from '@/store/helpers.js'
import util from '@/mixins/util.js'
const ExerciseInfo = () =>
  import(/* webpackPrefetch: true */ '@/components/dialogs/ExerciseInfo.vue')
import debounce from 'lodash/debounce'

export default {
  mixins: [util],
  components: { ExerciseInfo },
  name: 'ListWorkouts',
  data: () => ({
    name: '',
    description: '',
    workoutType: 'All',
    workouts: [],
    loadedList: [],
    typesList: [],
    repSchemes: [],
    muscleGroups: [],
    muscleGroup: { muscle_group: 'All', body_position: 'All' },
    selectedRepScheme: {},
    selectedWorkout: {},
    selectedExercise: {},
    showCopyDialog: false,
    showRepSchemeDialog: false,
    showExerciseInfo: false,
    all: true,
    hiit: false,
    wod: false,
    lifting: false,
    loaded: false,
    search: null,
    switchNum: 0,
    sortDesc: false,
    debouncedScroll: null,
    debounce: null,
    pagesize: 50,
    currentPage: 0,
    bottom: false,
    previousParams: ''
  }),
  beforeMount() {
    this.loadMuscleGroups()
    this.loadWorkoutTypes()
    this.loadPage()
  },
  mounted() {
    this.handleDebouncedScroll = debounce(this.handleScroll, 100)
    window.addEventListener('scroll', this.handleDebouncedScroll)
    window.addEventListener('message', this.receiveMessage)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleDebouncedScroll)
    window.removeEventListener('message', this.receiveMessage)
  },

  watch: {
    showExerciseInfo: function(value) {
      this.$store.dispatch('session/toggleModal', value)
    },
    bottom(bottom) {
      if (bottom && !this.filtering) {
        this.currentPage++
        this.loadPage()
      }
    }
  },
  computed: {
    ...appConfig,
    ...builder,
    itemsCount() {
      return this.workouts.length
    }
  },
  methods: {
    start(workout) {
      localStorage.setItem('startWorkout', true)
      let path = '/workouts/start/' + workout.workoutid
      if (workout.type == 'Circuit')
        path = '/workouts/start/hiit/' + workout.workoutid
      else if (workout.type == 'Hybrid') {
        let type = 'rft'
        if (workout.xmode == 'time') type = 'rft'
        else type = workout.xmode
        path = '/workouts/start/' + type + '/' + workout.workoutid
        // emom, rft, amrap
      }
      this.$router.push({
        path: path
      })
    },
    receiveMessage(event) {
      if (event.data.type == 'CLOSE_MODAL' && event.data.key == 'UoMc92xqvA') {
        if (this.showExerciseInfo) this.cancelInfo()
      }
    },
    debouncedSearch() {
      this.searching = true
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.loadPage()
      }, 50)
      this.searching = false
    },
    handleScroll(/*event*/) {
      this.isUserScrolling = window.scrollY > 0
      this.bottom = this.bottomVisible()
    },
    showExercise(exerciseid) {
      this.switchNum++
      this.selectedExercise = exerciseid
      this.showExerciseInfo = true
    },
    cancelInfo() {
      this.selectedExercise = ''
      this.showExerciseInfo = false
    },
    shortReset() {
      this.workouts = []
      this.loadedList = []
      this.currentPage = 0
      this.workoutType = 'All'
    },
    reset() {
      this.shortReset()
      this.muscleGroup = { muscle_group: 'All', body_position: 'All' }
    },
    searchMuscleGroup() {
      this.shortReset()
      this.loadPage()
    },
    filterWorkouts(type) {
      this.reset()
      this.workoutType = type
      this.loadPage()
    },

    copyWorkout(workout) {
      this.selectedWorkout = workout
      this.selectedWorkout.newname = this.selectedWorkout.name
      this.showCopyDialog = true
    },
    loadMuscleGroups() {
      return axios
        .get(this.baseURL + '/util/muscles/groups/all', {})
        .then(response => {
          if (response.status == 200) {
            this.muscleGroups = response.data.data
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadWorkoutTypes() {
      {
        return axios
          .get(this.baseURL + '/util/glossary/all', {})
          .then(response => {
            if (response.status == 200) {
              let glossary = response.data.data

              this.typesList = glossary.filter(function(item) {
                return item.category == 'Workout Types'
              })
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    copyNow() {
      return axios
        .post(this.baseURL + '/workout/copy', {
          workoutid: this.selectedWorkout.workoutid,
          description: this.selectedWorkout.description,
          newname: this.selectedWorkout.newname,
          now: this.now()
        })
        .then(response => {
          if (response.status == 200) {
            this.showCopyDialog = false
            this.planned = true

            this.toast(
              'Workout ' + this.selectedWorkout.newname + ' was copied!'
            )
            this.loadPage()
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadRepschemes() {
      return axios
        .get(this.baseURL + '/tools/repschemes/public/all', {})
        .then(response => {
          if (response.status == 200) {
            this.repSchemes = response.data.data
            this.loaded = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    repSchemeName(repschemeid) {
      let scheme = this.repSchemes.find(item => {
        return item.id == repschemeid
      })
      if (scheme) return scheme.name
      else return ''
    },
    selectRepScheme(repschemeid) {
      let scheme = this.repSchemes.filter(item => {
        return item.id == repschemeid
      })
      this.selectedRepScheme = scheme[0]
      this.selectedRepScheme.detailsObject = JSON.parse(
        this.selectedRepScheme.details
      )
      this.showRepSchemeDialog = true
    },
    loadPage() {
      this.loaded = false

      let url =
        '/workouts/database/search/' +
        this.userid +
        '/' +
        this.currentPage +
        '/' +
        this.pagesize

      const urlParams = new URLSearchParams()
      urlParams.append('musclegroup', this.muscleGroup.muscle_group)
      urlParams.append('type', this.workoutType)
      urlParams.append('search', this.search == null ? 'All' : this.search)

      let keepScrolling =
        this.previousParams == null ||
        this.previousParams.toString() == urlParams.toString()

      if (!keepScrolling) {
        this.currentPage = 0
      }

      return axios
        .get(this.baseURL + url, { params: urlParams })
        .then(response => {
          if (response.status == 200) {
            let newResponse = response.data.data
            newResponse.forEach(el => {
              el.exercises = JSON.parse(el.exercises)
              el.showExercises = false
            })

            if (keepScrolling) {
              this.workouts = this.workouts.concat(newResponse)
            } else {
              this.workouts = newResponse
            }
            this.loadedList = this.workouts.slice()
            this.loadRepschemes()
            this.previousParams = urlParams
            this.loaded = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>
