<template>
  <v-container>
    <BaseScreenHeader
      title="Public Exercises"
      screenInfo="EX-002"
      :showBack="true"
    />
    <v-sheet class="charcoal">
      <v-card-actions class="silver--text px-0">
        <v-spacer />
        <BaseLoginInvite text="for more searching options." />
      </v-card-actions>
      <v-card-actions class="pb-5 px-0 mt-n3">
        <v-text-field
          dark
          filled
          clearable
          :color="!loaded ? 'accent' : 'success'"
          :label="'Search ' + num_exercises + '  Exercises'"
          v-model="search"
          prepend-inner-icon="mdi-arm-flex"
          single-line
          hide-details
          @click:clear="debouncedSearch"
          @keydown.enter="debouncedSearch"
        />
        <v-icon
          color="paper"
          x-large
          title="Search Exercises"
          @click="debouncedSearch"
        >
          mdi-magnify
        </v-icon>
      </v-card-actions>
      <BaseLoading :loaded="loaded" label="Loading Exercises" />
      <v-card-text class="py-0 px-0">
        <v-row dense>
          <v-col
            v-for="item in exercises"
            :key="item.name"
            cols="12"
            md="6"
            xl="4"
          >
            <ExerciseDisplayTile
              :style="charcoalTileMenu"
              :exercise="item"
              :isSmall="isPhone"
              :loggedIn="loggedIn"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-sheet>
  </v-container>
</template>
<script>
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'
const ExerciseDisplayTile = () =>
  import('@/components/pageutil/ExerciseDisplayTile.vue')
import debounce from 'lodash/debounce'

export default {
  components: { ExerciseDisplayTile },
  mixins: [util],
  data: () => ({
    exercises: [],
    loadedList: [],
    search: null,
    num_exercises: 0,
    searching: false,
    loaded: false,
    previousParams: null,
    debouncedScroll: null,
    debounce: null,
    pagesize: 200,
    currentPage: 0,
    bottom: false
  }),
  beforeMount() {
    this.getExerciseCount()
    this.loadPage()
  },
  mounted() {
    this.handleDebouncedScroll = debounce(this.handleScroll, 100)
    window.addEventListener('scroll', this.handleDebouncedScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleDebouncedScroll)
  },

  watch: {
    bottom(bottom) {
      if (bottom) {
        this.currentPage++
        this.loadPage()
      }
    }
  },
  computed: {
    ...appConfig
  },
  methods: {
    debouncedSearch() {
      this.searching = true
      clearTimeout(this.debounce)
      this.debounce = setTimeout(() => {
        this.loadPage()
      }, 50)
    },
    handleScroll(/*event*/) {
      this.isUserScrolling = window.scrollY > 0
      this.bottom = this.bottomVisible()
    },
    viewDetails(value) {
      this.$router.push({
        name: 'viewexercise',
        params: { exerciseid: value.id }
      })
    },

    getExerciseCount() {
      {
        return axios
          .get(this.baseURL + '/exercises/count', {})
          .then(response => {
            if (response.status == 200) {
              this.num_exercises = response.data.data['num_exercises']
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    loadPage() {
      this.loaded = false
      let searchTerm = 'All'
      if (this.search != null) searchTerm = this.search
      const urlParams = new URLSearchParams()
      urlParams.append('search', searchTerm)

      let keepScrolling =
        (this.previousParams == null ||
          this.previousParams.toString() == urlParams.toString()) &&
        !this.searching
      if (!keepScrolling) this.currentPage = 0

      return axios
        .get(
          this.baseURL +
            '/exercises/public/' +
            this.currentPage +
            '/' +
            this.pagesize,
          { params: urlParams },
          {}
        )
        .then(response => {
          if (response.status == 200) {
            let newResponse = response.data.data
            if (keepScrolling) {
              this.exercises = this.exercises.concat(newResponse)
            } else {
              this.exercises = newResponse
            }
            this.loadedList = this.exercises.slice(0)
            this.previousParams = urlParams
            this.searching = false
            this.loaded = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>
