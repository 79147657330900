<template>
  <v-card class="paper" v-if="loggedIn && xvy" flat tile>
    <v-card-text class="title pt-5 pb-0">
      Create a sitemap
    </v-card-text>

    <v-card-text>
      <v-btn color="charcoal paper--text" class="" @click="loadExercises">
        Get all exercises {{ exercises.length }}
      </v-btn>
      <v-spacer />
      <v-btn color="charcoal paper--text" class="mt-2" @click="loadMuscles">
        Get all muscles {{ muscles.length }}
      </v-btn>
      <v-spacer />
      <v-btn
        color="charcoal paper--text"
        class="mt-2"
        @click="mode = 'sitemap'"
      >
        Public Routes
      </v-btn>
    </v-card-text>
    <v-card-actions> </v-card-actions>
    <v-card-actions>
      <v-spacer />
      <v-btn
        block
        large
        class="progressActive paper--text"
        @click="createSitemap"
      >
        Create Sitemap
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import axios from 'axios'
import { appConfig } from '@/store/helpers.js'
import util from '@/mixins/util.js'

export default {
  name: 'ExercisesXML',
  mixins: [util],
  data() {
    return {
      mode: 'exercises',
      exercises: [],
      muscles: [],
      workouts: []
    }
  },
  computed: {
    ...appConfig
  },
  methods: {
    //======= METHOD TO CREATE A SITEMAP FOR DYNAMIC CONTENT ==============
    createSitemap() {
      var doc = document.implementation.createDocument('', '', null)
      //create the outer tag
      var urlset = doc.createElement('urlset')
      urlset.setAttribute(
        'xmlns',
        'http://www.sitemaps.org/schemas/sitemap/0.9'
      )
      var url = ''
      var changefreq = ''
      var loc = ''
      var i = 0

      if (this.mode == 'exercises')
        for (i = 0; i < this.exercises.length; i++) {
          url = doc.createElement('url')
          loc = doc.createElement('loc')
          loc.innerHTML = 'https://bfit.fit/exercises/' + this.exercises[i].code
          changefreq = doc.createElement('changefreq')
          changefreq.innerHTML = 'weekly'
          url.appendChild(loc)
          url.appendChild(changefreq)
          urlset.appendChild(url)
        }
      else if (this.mode == 'muscles') {
        for (i = 0; i < this.muscles.length; i++) {
          url = doc.createElement('url')
          loc = doc.createElement('loc')
          loc.innerHTML =
            'https://bfit.fit/muscledetails/' + this.muscles[i].code
          changefreq = doc.createElement('changefreq')
          changefreq.innerHTML = 'monthly'
          url.appendChild(loc)
          url.appendChild(changefreq)
          urlset.appendChild(url)

          url = doc.createElement('url')
          loc = doc.createElement('loc')
          loc.innerHTML =
            'https://bfit.fit/search/bymuscle/' + this.muscles[i].code
          changefreq = doc.createElement('changefreq')
          changefreq.innerHTML = 'monthly'
          url.appendChild(loc)
          url.appendChild(changefreq)
          urlset.appendChild(url)
        }
      } else if (this.mode == 'sitemap') {
        // Access the defined routes from the router
        const routes = this.$router.options.routes
        const baseUrl = 'https://bfit.fit'
        // Filter routes that require authentication
        const noAuthRoutes = routes.filter(
          route =>
            route.meta && !route.meta.requiresAuth && !route.path.includes(':')
        )

        const urls = noAuthRoutes.map(route => `${baseUrl}${route.path}`)

        for (i = 0; i < urls.length; i++) {
          url = doc.createElement('url')
          loc = doc.createElement('loc')
          loc.innerHTML = urls[i]
          changefreq = doc.createElement('changefreq')
          changefreq.innerHTML = 'weekly'
          url.appendChild(loc)
          url.appendChild(changefreq)
          urlset.appendChild(url)
        }
      }
      doc.appendChild(urlset)
      //serialize the xml file to txt
      var oSerializer = new XMLSerializer()
      var xmltext = oSerializer.serializeToString(doc)
      xmltext = '<?xml version="1.0" encoding="UTF-8"?>' + xmltext
      //download the txt
      var pom = document.createElement('a')
      var filename = this.mode + '.xml'
      pom = document.createElement('a')
      var bb = new Blob([xmltext], { type: 'text/plain' })
      pom.setAttribute('href', window.URL.createObjectURL(bb))
      pom.setAttribute('download', filename)
      pom.dataset.downloadurl = ['text/plain', pom.download, pom.href].join(':')
      pom.draggable = true
      pom.classList.add('dragout')
      pom.click()
    },
    loadExercises() {
      return axios
        .get(this.baseURL + '/exercises/list/all', {})
        .then(response => {
          if (response.status == 200) {
            this.mode = 'exercises'
            this.exercises = response.data.data
            this.loaded = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    loadMuscles() {
      return axios
        .get(this.baseURL + '/util/muscles/all', {})
        .then(response => {
          if (response.status == 200) {
            this.mode = 'muscles'
            this.muscles = response.data.data
            this.loaded = true
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>
